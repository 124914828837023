import React from "react";

export default function IcoSupport() {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      className="fill-current"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.25959 11.7769C4.29097 12.7964 3.80544 13.2903 2.96997 15.1301C2.40643 14.5916 1.85349 14.0636 1.28388 13.519C1.61565 12.979 1.93756 12.4548 2.25872 11.9299C2.47914 11.5686 2.211 10.8982 1.80349 10.7967C1.20511 10.6483 0.605962 10.5051 0 10.3574C0 9.20229 0 8.06763 0 6.88903C0.588541 6.74739 1.18466 6.6065 1.77926 6.46031C2.21555 6.35276 2.48672 5.68923 2.25342 5.30671C1.93832 4.79089 1.62095 4.27582 1.31645 3.77969C2.14359 2.95179 2.95331 2.14283 3.78499 1.31191C4.28491 1.61868 4.80982 1.94211 5.33701 2.26327C5.67787 2.47081 6.35276 2.20116 6.44819 1.81713C6.59741 1.21798 6.73981 0.616567 6.88979 0C8.04263 0 9.18639 0 10.3627 0C10.5013 0.579451 10.6407 1.16496 10.7831 1.74972C10.8967 2.21555 11.5489 2.48975 11.9526 2.24206C12.4677 1.9262 12.9812 1.61034 13.4698 1.31039C14.2962 2.13677 15.0983 2.93892 15.936 3.77742C15.6391 4.26294 15.318 4.78559 14.9983 5.30974C14.7658 5.69074 15.04 6.35048 15.4778 6.45804C16.0641 6.60196 16.6526 6.74057 17.2502 6.88449C17.2502 8.04718 17.2502 9.1932 17.2502 10.3627C16.6943 10.4945 16.1428 10.624 15.5922 10.7551C14.9923 10.8982 14.7362 11.508 15.0559 12.0283C15.3521 12.5108 15.6475 12.9941 15.955 13.4948C15.4286 14.0583 14.9165 14.606 14.3689 15.1915C13.7614 13.5281 12.6692 12.4101 11.0634 11.7935C13.1335 9.92414 12.6858 7.32608 11.2823 6.01266C9.77341 4.60001 7.41092 4.6394 5.95055 6.11037C4.57047 7.50105 4.29248 10.0234 6.25959 11.7769Z" />
      <path d="M13.4616 19.3677C10.2492 19.3677 7.08229 19.3677 3.85857 19.3677C3.85857 19.1518 3.85554 18.9428 3.85933 18.7337C3.87372 17.9141 3.8207 17.0855 3.92068 16.2758C4.17594 14.22 5.83098 12.7294 7.90488 12.6635C8.68203 12.6385 9.48266 12.6347 10.2386 12.7885C12.0527 13.1581 13.3479 14.7109 13.4525 16.5704C13.504 17.4869 13.4616 18.4088 13.4616 19.3677Z" />
      <path d="M8.64853 11.4512C7.17074 11.4421 5.97321 10.2294 5.99442 8.76523C6.01638 7.29123 7.22982 6.10582 8.69852 6.12324C10.1483 6.1399 11.339 7.34804 11.3337 8.79704C11.3292 10.2703 10.1248 11.4602 8.64853 11.4512Z" />
    </svg>
  );
}
