import { useEffect } from "react";
import ThinBag from "../../../Helpers/icons/ThinBag";
import Middlebar from "./Middlebar";
import Navbar from "./Navbar";
import TopBar from "./TopBar";

export default function HeaderOne({ className, drawerAction, type = 1, slug, webLogo, mainColor }) {
  useEffect(() => {
    // alert("Header : " + mainColor);
  }, [mainColor]);

  return (
    < header className={` ${className || ""} header-section-wrapper relative`} >
      <TopBar className="quomodo-shop-top-bar" slug={slug} slug={slug} webLogo={webLogo} mainColor={mainColor} />
      <Middlebar type={type} className="quomodo-shop-middle-bar lg:block hidden" slug={slug} webLogo={webLogo} mainColor={mainColor} />
      <div className="quomodo-shop-drawer lg:hidden block w-full h-[60px] bg-white">
        <div className="w-full h-full flex justify-between items-center px-5">
          <div onClick={drawerAction}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16M4 18h7"
              />
            </svg>
          </div>

          <div>
            {type === 3 ? (
              <a href="/">
                <img
                  width="152"
                  height="36"
                  //src={`${process.env.PUBLIC_URL}/assets/images/logo-2.svg`}
                  src={webLogo}
                  alt="logo"
                />
              </a>
            ) : (
              <a href={`/${slug}`}>
                <img
                  width="152"
                  height="36"
                  // src={`${process.env.PUBLIC_URL}/assets/images/logo1.png`}
                  src={webLogo}
                  alt="logo"
                />
              </a>
            )}
          </div>
          <div className="cart relative cursor-pointer">
            <a href={`/${slug}/cart`} >
              <span>
                <ThinBag />
              </span>
            </a>
            <span className={`w-[18px] h-[18px] rounded-full  absolute -top-2.5 -right-2.5 flex justify-center items-center text-[9px] ${type === 3 ? 'bg-qh3-blue text-white' : 'bg-qyellow text-qblack'}`}>
              15
            </span>
          </div>
        </div>
      </div>
      <Navbar type={type} className="quomodo-shop-nav-bar lg:block hidden" mainColor={mainColor} />
    </header >
  );
}
