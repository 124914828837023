export function getContrastYIQ(rgba) {
    const matches = rgba.match(/rgba?\((\d+), (\d+), (\d+)(?:, [\d.]+)?\)/);
    if (matches) {
      const r = parseInt(matches[1], 10);
      const g = parseInt(matches[2], 10);
      const b = parseInt(matches[3], 10);
      const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
      return (yiq >= 128) ? 'black' : 'white';
    }
    return 'black';
  }