export default function Empty() {
  return (
    <svg
      width="528"
      height="420"
      viewBox="0 0 528 420"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M159.677 96.061L141.809 119.456L268.442 216.044L286.31 192.649L159.677 96.061Z"
        fill="#36B1BA"
      />
      <path
        d="M159.691 96.0558L93.3987 45.4922L75.5308 68.8873L141.823 119.451L159.691 96.0558Z"
        fill="#30AEB7"
      />
      <path
        d="M268.434 216.046L75.5081 68.8945L-0.000869075 167.761L192.925 314.913L268.434 216.046Z"
        fill="#56C37F"
      />
      <path
        d="M141.8 119.452L75.5081 68.8887L-0.000869075 167.755L66.2914 218.319L141.8 119.452Z"
        fill="#3BA860"
      />
      <path
        d="M159.691 96.0558L93.3987 45.4922L75.5308 68.8873L141.823 119.451L159.691 96.0558Z"
        fill="#FFBB38"
      />
      <path
        d="M286.317 192.634L159.684 96.0459L141.816 119.441L268.449 216.029L286.317 192.634Z"
        fill="#FFBB38"
      />
      <path
        d="M211.998 112.411C211.927 111.704 212.564 112.889 211.998 112.411V112.411Z"
        fill="#D4E2FF"
      />
      <path
        d="M46.4978 103.762C47.2411 103.673 47.9491 103.886 48.5332 104.346L177.558 203.27L223.309 144.655C225.115 142.408 229.309 145.964 227.734 148.033L181.929 206.701L241.876 251.998C242.46 252.458 242.831 253.13 242.92 253.855C243.008 254.598 242.796 255.323 242.336 255.889C241.876 256.473 241.221 256.844 240.477 256.933C239.734 257.004 239.008 256.809 238.442 256.349L178.514 211.052L133.824 268.907C133.045 269.898 131.718 270.198 130.585 269.738C130.337 269.632 130.107 269.508 129.895 269.332C129.718 269.19 129.399 268.819 129.275 268.624C128.674 267.634 128.744 266.36 129.452 265.458L174.124 207.639L45.0642 108.697C44.8872 108.555 44.5509 108.166 44.4447 107.971C43.843 106.981 43.9138 105.707 44.6394 104.805C45.0996 104.222 45.7544 103.85 46.4978 103.762Z"
        fill="#00C7E6"
      />
      <path
        d="M167.932 78.8063C167.861 78.0811 168.498 79.2838 167.932 78.8063V78.8063Z"
        fill="#D4E2FF"
      />
      <path
        d="M219.805 121.733C221.31 121.432 222.301 119.964 222 118.478C221.628 116.904 220.177 115.984 218.708 116.285C214.212 117.169 209.557 117.293 204.956 116.657C209.947 115.206 212.195 112.093 211.628 107.371C211.522 106.38 211.168 103.32 208.354 101.428C206.301 100.048 203.345 99.6061 199.31 100.084C195.558 100.526 191.398 102.401 187.965 104.311C188.991 100.544 189.823 96.051 189.381 92.3013C188.903 88.2863 187.77 85.5094 185.93 83.8291C183.452 81.5652 180.372 81.9366 179.363 82.0604C178.354 82.1842 175.292 82.5557 173.416 85.3325C172.231 87.1013 171.735 89.5421 171.93 92.7435C169.806 88.3394 168.425 83.6346 167.93 78.8237C167.346 78.3461 165.629 76.259 164.868 76.3475C164.142 76.4182 163.47 76.7719 162.992 77.3556C162.532 77.9393 162.302 78.6645 162.39 79.4073C163.116 86.3761 165.487 93.3272 169.275 99.5177C171.682 103.462 174.655 106.999 178.089 110.112L184.655 115.118C194.991 121.591 207.876 124.085 219.805 121.733ZM182.071 104.576C179.912 100.95 177.965 96.8292 177.593 93.7163C177.169 90.1611 177.753 88.8523 178.036 88.4455C178.177 88.2332 178.496 87.7734 180.018 87.5965C181.558 87.4019 181.965 87.7734 182.16 87.9503C182.602 88.3571 183.416 89.5775 183.823 92.9734C184.266 96.4047 183.168 101.056 182.071 104.576ZM200.708 111.846C197.257 112.252 192.531 111.12 189.098 110.077C192.195 108.237 196.513 106.027 199.947 105.62C202.761 105.284 204.177 105.584 204.85 105.85C205.027 105.92 205.15 105.991 205.239 106.044C205.469 106.203 205.912 106.504 206.089 108.025C206.283 109.564 206.478 111.156 200.708 111.846Z"
        fill="#FFBB38"
      />
      <path
        d="M251.874 335.995C251.237 333.908 248.459 333.289 246.583 334.298C244.423 335.465 242.441 335.854 243.22 338.206C245.096 343.972 251.273 365.551 251.273 365.551L98.2484 365.321C96.036 365.321 94.2307 367.231 94.2307 369.583C94.2307 371.936 95.9829 373.828 98.1776 373.864V393.532C98.1776 395.283 99.505 396.698 101.151 396.698C102.797 396.698 104.124 395.283 104.124 393.532V373.881C110 373.881 118.177 373.899 127.699 373.917V394.186C127.699 395.937 129.027 397.352 130.673 397.352C132.319 397.352 133.646 395.937 133.646 394.186V373.935C152.902 373.97 176.495 374.005 197.981 374.023V393.532C197.981 395.283 199.309 396.698 200.955 396.698C202.601 396.698 203.928 395.283 203.928 393.532V374.041C212.725 374.058 221.043 374.058 228.423 374.076V393.532C228.423 395.283 229.751 396.698 231.397 396.698C233.043 396.698 234.37 395.283 234.37 393.532V374.076C248.423 374.094 257.91 374.111 258.653 374.111C258.671 374.111 258.689 374.111 258.706 374.111C259.627 374.111 260.565 373.775 261.379 373.05C262.281 372.254 262.724 371.069 262.724 369.831C262.724 368.487 254.405 344.238 251.874 335.995Z"
        fill="#A6C3FF"
      />
      <path
        d="M101.135 402.995C106.038 402.995 110.02 398.75 110.02 393.532C110.02 388.297 106.038 384.069 101.135 384.069C96.2325 384.069 92.2502 388.314 92.2502 393.532C92.2679 398.767 96.2325 402.995 101.135 402.995Z"
        fill="#2D4559"
      />
      <path
        d="M200.955 402.995C205.858 402.995 209.84 398.75 209.84 393.532C209.84 388.297 205.858 384.069 200.955 384.069C196.053 384.069 192.07 388.314 192.07 393.532C192.07 398.767 196.053 402.995 200.955 402.995Z"
        fill="#2D4559"
      />
      <path
        d="M130.674 402.995C135.577 402.995 139.559 398.75 139.559 393.532C139.559 388.297 135.577 384.069 130.674 384.069C125.772 384.069 121.789 388.314 121.789 393.532C121.789 398.767 125.772 402.995 130.674 402.995Z"
        fill="#2D4559"
      />
      <path
        d="M231.237 402.995C236.139 402.995 240.121 398.75 240.121 393.532C240.121 388.297 236.139 384.069 231.237 384.069C226.334 384.069 222.352 388.314 222.352 393.532C222.352 398.767 226.334 402.995 231.237 402.995Z"
        fill="#2D4559"
      />
      <path
        d="M251.91 338.754C253.025 338.754 254.069 337.958 254.387 336.756L288.245 207.586C288.546 206.419 289.537 205.588 290.688 205.588C294.635 205.552 295.325 205.481 298.723 205.446C300.139 205.428 301.272 204.19 301.254 202.669C301.236 201.148 300.086 199.945 298.652 199.963L289.856 200.122C286.086 200.158 284.759 201.413 283.856 203.925L249.414 335.288C249.025 336.738 249.839 338.259 251.202 338.666C251.432 338.719 251.68 338.754 251.91 338.754Z"
        fill="#D4E2FF"
      />
      <path
        d="M91.5939 337.233C90.9922 336.738 90.6028 335.96 90.5851 335.093L90.4612 236.628C90.4612 235.903 90.7267 235.196 91.2045 234.665C91.6824 234.152 92.355 233.851 93.0275 233.851L281.751 219.454C283.166 219.454 284.317 220.675 284.317 222.196C284.317 223.717 283.166 224.937 281.751 224.937L95.6469 239.335L95.7177 333.342H251.91C253.326 333.342 254.477 334.562 254.477 336.083C254.477 337.605 253.326 338.825 251.91 338.825H94.1072C92.7797 338.825 92.0187 337.87 91.5939 337.233Z"
        fill="#D4E2FF"
      />
      <path
        d="M299.097 207.798H337.132C339.734 207.798 341.857 205.552 341.857 202.758C341.857 199.981 339.734 197.717 337.132 197.717H299.097C296.495 197.717 294.371 199.963 294.371 202.758C294.371 205.552 296.477 207.798 299.097 207.798Z"
        fill="#798895"
      />
      <path
        d="M125.755 337.145C126.427 337.145 126.958 336.632 126.958 335.995V234.312C126.958 233.675 126.427 233.162 125.755 233.162C125.082 233.162 124.551 233.675 124.551 234.312V335.995C124.551 336.632 125.082 337.145 125.755 337.145Z"
        fill="#D4E2FF"
      />
      <path
        d="M156.391 337.145C157.064 337.145 157.595 336.615 157.595 335.96V231.323C157.595 230.668 157.064 230.138 156.391 230.138C155.719 230.138 155.188 230.668 155.188 231.323V335.96C155.188 336.615 155.719 337.145 156.391 337.145Z"
        fill="#D4E2FF"
      />
      <path
        d="M187.028 337.145C187.701 337.145 188.232 336.615 188.232 335.96V231.323C188.232 230.668 187.701 230.138 187.028 230.138C186.355 230.138 185.824 230.668 185.824 231.323V335.96C185.824 336.615 186.355 337.145 187.028 337.145Z"
        fill="#D4E2FF"
      />
      <path
        d="M248.301 337.144C248.974 337.144 249.505 336.578 249.505 335.888V224.406C249.505 223.716 248.974 223.15 248.301 223.15C247.629 223.15 247.098 223.716 247.098 224.406V335.888C247.098 336.578 247.629 337.144 248.301 337.144Z"
        fill="#D4E2FF"
      />
      <path
        d="M217.665 337.145C218.337 337.145 218.868 336.597 218.868 335.925V228.21C218.868 227.538 218.337 226.989 217.665 226.989C216.992 226.989 216.461 227.538 216.461 228.21V335.925C216.461 336.597 216.992 337.145 217.665 337.145Z"
        fill="#D4E2FF"
      />
      <path
        d="M93.2741 254.263H273.626C274.298 254.263 274.829 253.679 274.829 252.972C274.829 252.264 274.298 251.698 273.626 251.698H93.2741C92.6015 251.698 92.0706 252.264 92.0706 252.972C92.0706 253.697 92.6192 254.263 93.2741 254.263Z"
        fill="#D4E2FF"
      />
      <path
        d="M91.6842 292.237H263.699C264.372 292.237 264.903 291.671 264.903 290.945C264.903 290.238 264.372 289.654 263.699 289.654H91.6842C91.0117 289.654 90.4807 290.238 90.4807 290.945C90.4807 291.653 91.0117 292.237 91.6842 292.237Z"
        fill="#D4E2FF"
      />
      <path
        d="M481.406 362.4L469.492 368.716L484.18 396.387L496.094 390.072L481.406 362.4Z"
        fill="#FFB8BC"
      />
      <path
        d="M495.342 387.466C490.882 389.252 480.582 395.142 480.564 395.142C480.564 395.142 470.334 406.267 470.352 411.149C470.369 414.227 475.13 413.767 477.219 412.422L500.971 395.903L495.342 387.466Z"
        fill="#2D4559"
      />
      <path
        d="M495.341 387.466C495.341 387.466 487.678 396.079 480.563 395.142C480.669 395.036 482.244 393.232 482.35 393.108C482.35 393.108 487.554 384.759 492.067 382.92C492.067 382.92 495.359 387.466 495.341 387.466Z"
        fill="#FFB8BC"
      />
      <path
        d="M430.439 175.715H392.688V203.537H430.439V175.715Z"
        fill="#2D4559"
      />
      <path
        d="M409.45 375.246L395.992 376.127L398.042 407.385L411.5 406.504L409.45 375.246Z"
        fill="#FFB8BC"
      />
      <path
        d="M411.891 403.757C407.094 403.562 395.272 404.712 395.272 404.712C395.272 404.712 381.378 410.672 379.396 415.129C378.139 417.942 382.67 419.463 385.13 419.109L413.572 413.767L411.891 403.757Z"
        fill="#2D4559"
      />
      <path
        d="M411.893 403.756C411.893 403.756 401.38 408.478 395.273 404.711C395.415 404.64 397.592 403.65 397.734 403.597C397.734 403.597 405.91 398.114 410.778 398.273C410.76 398.291 411.893 403.774 411.893 403.756Z"
        fill="#FFB8BC"
      />
      <path
        d="M395.272 394.983C395.131 393.285 376.954 270.836 384.83 168.286L416.245 198.814C411.891 300.763 411.821 393.108 411.891 393.975L395.272 394.983Z"
        fill="#2D4559"
      />
      <path
        d="M305.308 199.097C304.972 198.389 304.742 197.629 304.636 196.833C304.087 192.606 307.06 188.732 311.273 188.184C345.219 183.709 370.759 153.163 386.422 122.741C388.369 118.956 398.333 126.332 402.121 128.277C405.909 130.223 407.413 134.875 405.448 138.66C387.502 173.521 352.175 198.354 313.29 203.466C309.892 203.908 306.706 202.051 305.308 199.097Z"
        fill="#FFB8BC"
      />
      <path
        d="M384.491 171.541C392.562 163.139 405.889 145.505 408.703 130.506C409.447 126.562 408.491 121.45 405.199 118.833C400.987 115.49 391.571 119.346 387.305 121.327C379.96 124.74 377.624 139.88 364.031 154.101C371.376 160.433 376.987 166.995 384.491 171.541Z"
        fill="#5CC0F7"
      />
      <path
        d="M454.141 231.536C463.398 208.878 455.203 183.427 434.619 178.704L414.018 199.504C407.487 292.008 474.601 381.311 476.636 385.096L491.592 377.614C491.202 376.871 458.955 305.539 454.141 231.536Z"
        fill="#2D4559"
      />
      <path
        d="M447.484 185.461H383.786C383.786 185.461 378.512 139.28 378.512 139.227C378.512 120.85 394.989 113.828 410.777 113.474C429.113 113.05 456.581 114.783 456.581 139.227L447.484 185.461Z"
        fill="#3EA2E5"
      />
      <path
        d="M411.786 163.882H387.663C378.778 163.882 372.406 155.304 374.99 146.796L379.256 132.771L411.786 137.917V163.882Z"
        fill="#3EA2E5"
      />
      <path
        d="M415.805 123.733C421.044 123.733 425.274 119.488 425.274 114.27C425.274 114.128 425.256 113.987 425.256 113.845C420.23 113.403 415.221 113.368 410.761 113.474C409.292 113.509 407.823 113.598 406.354 113.757C406.354 113.934 406.336 114.093 406.336 114.27C406.336 119.488 410.584 123.733 415.805 123.733Z"
        fill="#5CC0F7"
      />
      <path
        d="M428.932 76.9322C429.8 75.8179 430.277 74.456 430.808 73.1648C431.357 71.8559 432.012 70.5471 433.162 69.7158C434.295 68.8845 436.012 68.6723 437.074 69.6097C437.587 70.0519 437.888 70.7063 438.295 71.2723C439.569 73.0587 441.888 73.89 444.083 73.9784C446.295 74.0668 448.454 73.5362 450.631 73.1648C452.808 72.7934 455.074 72.5634 457.18 73.2355C458.047 73.5185 458.879 73.9607 459.463 74.6505C460.047 75.3403 460.33 76.3308 460.047 77.1798C459.746 78.0995 458.861 78.7186 458.525 79.6206C458.189 80.4873 458.401 81.5131 458.914 82.2914C459.41 83.0873 460.171 83.671 460.932 84.2016C462.879 85.5281 465.091 86.5009 466.755 88.1458C468.419 89.8084 469.427 92.4969 468.259 94.5309C467.534 95.8044 466.118 96.5649 464.719 96.9894C459.622 98.5636 454.1 96.9364 449.233 94.7255C444.383 92.5323 439.676 89.72 434.419 88.8003C432.879 88.535 431.251 88.4111 429.87 87.6683C428.401 86.8547 427.375 85.4043 426.773 83.8302C426.348 82.7159 425.516 80.5757 425.906 79.373C426.295 78.2587 428.171 77.905 428.932 76.9322Z"
        fill="#2D4559"
      />
      <path
        d="M431.113 77.6221C433.29 80.2221 434.104 84.0956 433.379 87.3501C432.865 89.5963 431.113 91.8072 428.618 91.6127C427.131 91.4889 425.821 90.4807 424.989 89.2426C424.157 88.0045 423.697 86.5541 423.272 85.1215C422.547 82.7514 421.538 78.5242 423.343 76.3133C425.325 73.8724 428.741 75.1459 430.617 77.0561C430.777 77.233 430.954 77.4276 431.113 77.6221Z"
        fill="#00C7E6"
      />
      <path
        d="M426.035 78.0819C422.955 73.7663 417.822 70.9363 412.53 70.8125L411.539 72.422C412.566 80.2751 413.663 88.2874 417.167 95.38C418.141 97.3433 419.486 99.3773 421.61 99.9079C423.415 100.368 425.362 99.5542 426.654 98.1923C427.928 96.848 428.619 95.0263 429.026 93.2222C430.141 88.0398 429.114 82.3799 426.035 78.0819Z"
        fill="#2D4559"
      />
      <path
        d="M411.996 88.4819L403.005 87.9513C400.704 87.8098 398.704 89.5785 398.563 91.8955C398.421 94.1949 400.191 96.1935 402.492 96.335L411.979 96.901V88.4819H411.996Z"
        fill="#FF808C"
      />
      <path
        d="M413.766 97.0421L422.757 97.5728C425.058 97.7143 427.058 95.9455 427.199 93.6285C427.341 91.3292 425.571 89.3305 423.27 89.189L413.783 88.623V97.0421H413.766Z"
        fill="#FFB8BC"
      />
      <path
        d="M415.693 120.496C412.419 120.354 409.888 117.578 410.029 114.305L410.631 100.722L423.427 97.5557L421.87 114.836C421.728 118.108 418.949 120.637 415.693 120.496Z"
        fill="#FFB8BC"
      />
      <path
        d="M419.677 96.9893L410.828 97.4138L410.031 113.421C416.226 113.155 420.509 108.451 421.075 103.109C421.182 102.012 419.677 96.9893 419.677 96.9893Z"
        fill="#FF808C"
      />
      <path
        d="M410.863 109.529C404.367 109.14 399.359 103.516 399.748 97.0067L400.615 82.5917C401.005 76.1005 406.633 71.095 413.146 71.4841C419.641 71.8732 424.65 77.4978 424.261 84.0067L423.394 98.4217C422.987 104.931 417.358 109.918 410.863 109.529Z"
        fill="#FFB8BC"
      />
      <path
        d="M399.889 75.2696C394.863 82.6982 413.482 98.1037 425.287 88.6411C436.42 79.7267 410.013 60.2708 399.889 75.2696Z"
        fill="#2D4559"
      />
      <path
        d="M527.763 329.151L515.622 244.73L515.604 244.996V244.73L455.357 247.667L452.508 332.813L527.763 329.151Z"
        fill="#00C7E6"
      />
      <path
        d="M493.678 248.409C492.899 248.445 492.244 247.843 492.209 247.083L491.908 240.733C491.696 236.417 488.014 233.092 483.713 233.304C479.395 233.517 476.068 237.196 476.28 241.493L476.581 247.843C476.616 248.621 476.014 249.276 475.253 249.311C474.475 249.347 473.82 248.745 473.784 247.985L473.466 241.635C473.183 235.781 477.714 230.775 483.572 230.492C489.43 230.209 494.439 234.737 494.722 240.591L495.041 246.941C495.058 247.702 494.457 248.374 493.678 248.409Z"
        fill="#009DB5"
      />
      <path
        d="M485.34 235.516C484.579 235.657 483.783 235.692 482.969 235.604C478.739 235.109 475.694 231.288 476.19 227.043C480.155 193.049 456.757 160.84 431.076 138.201C427.872 135.388 437.483 127.535 440.297 124.352C443.111 121.15 447.996 120.85 451.199 123.662C480.632 149.591 496.084 189.883 491.535 228.83C491.128 232.261 488.544 234.897 485.34 235.516Z"
        fill="#FFB8BC"
      />
      <path
        d="M474.74 149.167C468.563 139.297 457.943 124.97 444.085 118.568C440.439 116.887 435.253 116.551 431.891 119.116C427.625 122.37 428.404 130.471 429.289 135.088C430.811 143.047 439.448 148.671 444.917 154.137C445.997 155.216 456.05 167.65 456.05 167.65C462.262 161.477 468.492 155.322 474.74 149.167Z"
        fill="#3EA2E5"
      />
      <path
        d="M86.3752 33.2987C85.2956 33.2987 84.3222 32.5558 84.0567 31.4769L77.2249 2.92979C76.924 1.65632 77.7028 0.365152 78.9948 0.0644695C80.2868 -0.236213 81.5611 0.542023 81.862 1.83319L88.6938 30.3626C88.9947 31.6361 88.2159 32.9273 86.9239 33.2279C86.7469 33.2633 86.5522 33.2987 86.3752 33.2987Z"
        fill="#FFBB38"
      />
      <path
        d="M31.3121 82.5755C30.9405 82.5755 30.5688 82.4871 30.2148 82.3102L3.8435 68.6203C2.67537 68.0189 2.2152 66.5686 2.83466 65.4012C3.43642 64.2339 4.88773 63.774 6.05586 64.3931L32.4272 78.083C33.5953 78.6843 34.0555 80.1347 33.436 81.302C33.0112 82.098 32.1794 82.5755 31.3121 82.5755Z"
        fill="#FFBB38"
      />
      <path
        d="M157.648 51.4101C157.152 51.4101 156.656 51.251 156.214 50.9326C155.152 50.1367 154.94 48.6509 155.736 47.5897L173.169 24.3488C173.966 23.2875 175.453 23.0753 176.514 23.8712C177.576 24.6671 177.789 26.1528 176.992 27.2141L159.559 50.455C159.081 51.0918 158.373 51.4101 157.648 51.4101Z"
        fill="#FFBB38"
      />
    </svg>
  );
}
