import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { useParams } from "react-router-dom";
import { useCart } from '../../CartContext';
import animationData from '../../succes01.json';
import LayoutNoDiscount from "../Partials/LayoutNoDiscount";
import './loading.css';

export default function OrderPlaced() {
  const defaultOptions = {
    loop: 1,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const { slug, orderId } = useParams();

  const { cart, getTotalQuantity, getTotalPrice, addItemToCart, removeItemFromCart } = useCart();
  const [loading, setLoading] = useState(false);

  /********************************************************************************* */
  /*****************************     LOAD DTA     ********************************** */
  /********************************************************************************* */
  const [bannerImage, setBannerImage] = useState([['/assets/images/banner_long_1__2500__1251__.jpg']]);
  const [bannerImage2, setBannerImage2] = useState([['/assets/images/mbanner_1_1.jpeg']]);
  const [businessData, setBusinessData] = useState();
  const [productData, setProductData] = useState();
  const [productData2, setProductData2] = useState();
  const [webLogo, setWebLogo] = useState();
  const [shopfrontData, setShopfrontData] = useState();
  const [categoryData, setCategoryData] = useState();
  const [mainColor, setMainColor] = useState();

  //const [oneProduct, setOneProduct] = useState(null);

  useEffect(() => {
    console.log("slug === " + slug);

    const fetchAllWebData = async (slug) => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/businesses/getShopfrontMainBannerImageURL/` + slug);
        if (response.data) {
          setBusinessData(response.data.businessData);

          //const sortedData = [...data].sort((a, b) => a.order - b.order); //ในกรณีอยาก copy
          (response.data.shopfrontData[0].layouts).sort((a, b) => a.order - b.order);

          const data = response.data.shopfrontData[0].layouts;
          const extractImageUrls = key => data.map(item => item.content.map(content => content[key]));

          setBannerImage(extractImageUrls("imageUrl"));
          setBannerImage2(extractImageUrls("imageUrl2"));
          (response.data.productData).sort((a, b) => {
            const dateA = new Date(a.lastUpdate);
            const dateB = new Date(b.lastUpdate);
            return dateB - dateA;
          });
          setProductData(response.data.productData);
          setProductData2(response.data.productData);
          setShopfrontData(response.data.shopfrontData[0]);
          //alert(JSON.stringify(response.data.shopfrontData, null, 2));
          setCategoryData(response.data.shopfrontData[0]);
          setWebLogo(response.data.shopfrontData[0].logoImageURL);

          //alert(response.data.shopfrontData[0].logoImageURL);
        } else {
          //await alert("error1 ---- " + response.data);
        }

      } catch (error) {
        //await alert("error2 ---- " + error);
        console.error("Failed to fetch banner image:", error);
      }
    };

    fetchAllWebData(slug);

  }, [slug]);

  useEffect(() => {
    if (shopfrontData) {
      const colorR = shopfrontData.mainColor;
      //alert(JSON.stringify(shopfrontData, null, 2));
      const rgbaString = `rgba(${colorR[0]}, ${colorR[1]}, ${colorR[2]}, ${colorR[3] / 255})`;
      //setBannerImage(shopfrontData.layouts[0].content[0].imageUrl);
      setMainColor(rgbaString);
    }
  }, [shopfrontData]);

  useEffect(() => {
    console.log(bannerImage);
  }, [bannerImage]);


  /********************************************************************************* */
  /*********************************   Save Order  ********************************* */
  /********************************************************************************* */

  const handleClick = () => {
    setLoading(true);
    setTimeout(async () => {
      setLoading(false);
      await saveOrder();
    }, 2000);
  };

  const saveOrder = async (status = 'paid') => {
    console.log(JSON.stringify(cart, null, 2));

    var productInCart = [];

    for (let i = 0; i < cart.items.length; i++) {
      (cart.items.name);
      productInCart.push({
        'name': cart.items[i].name,
        'price': cart.items[i].variants[0].price,
        'brand': cart.items[i].name,
        'imageURL': cart.items[i].imageUrls[0],
        'num': cart.items[i].quantity,
        'headerName1': cart.items[i].variantsOptions[0] != null ? cart.items[i].variantsOptions[0].name : "",
        'headerName2': cart.items[i].variantsOptions[1] != null ? cart.items[i].variantsOptions[1].name : "",
        'productId': cart.items[i].productId,
        'variantIndex': 0,
        'productIndex': 0,
      });
    }

    const passData = {
      'businessId': businessData.businessId,
      'employeeId': 'shopfront',
      'isGift': false,
      'lastUpdateAt': Date.now().toString(),
      'orderItems': productInCart,
      'paymentStatus': status,
      'subtotalPrice': getTotalPrice(),
      'totalPrice': getTotalPrice(),
    };
    console.log('passData =', passData);

    try {
      // const headers = {
      //   'Content-Type': 'application/json',
      //   'Authorization': accessToken,
      //   'Hardware-ID': deviceId,
      //   'Mac-Address': macAddress,
      // };
      const headers = {
        'Content-Type': 'application/json',
        'role': 'superadmin',
        'x-super-admin-api-key': 'TempAPIkey0123456789',
      };
      console.log('Saving Order: new Order Save');
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/orders`, passData, { headers: headers });

      if (response.data) {
        console.log('Order saved:', response.data);
      }
    } catch (e) {
      console.error('Error Making API Request:', e);
    }
  };
  /********************************************************************************* */
  /********************************************************************************* */
  /********************************************************************************* */

  return (
    <>
      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
      <LayoutNoDiscount childrenClasses="pt-0 pb-0" webLogo={webLogo} slug={slug} mainColor={mainColor}>
        <center>
          <div style={{
            display: "flex",
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '60vh',
            marginTop: '5vh',
            minWidth: '370px',
            width: '30vw',
          }} >
            <div style={{ fontSize: '40px' }}>
              <Lottie options={defaultOptions} height={250} width={250} />
              ได้รับออเดอร์เรียบร้อยแล้ว<br />
            </div>
            <div style={{ fontSize: '18px' }}>
              หมายเลขออเดอร์ &nbsp; <span style={{ fontWeight: '800' }}>#{orderId}</span>
            </div>
            <div style={{ fontSize: '14px', color: 'gray' }}>
              <br />กรุณาจดบันทึกเลขออร์เดอร์ด้านบนไว้
            </div>
            <br /><br />
            <a href={`/${slug}`}>
              <div className="w-full h-[50px] black-btn flex justify-center items-center" style={{ width: '300px' }}>
                <span className="text-sm font-semibold">
                  กลับสู่หน้า Shopping
                </span>
              </div>
            </a>
          </div >
        </center>
      </LayoutNoDiscount >
    </>
  );
}
