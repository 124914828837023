import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useCart } from '../../CartContext';
import InputCom from "../Helpers/InputCom";
import Layout from "../Partials/Layout";
import './loading.css';

export default function CheakoutPage() {
  const { slug } = useParams();
  const { cart, getTotalQuantity, getTotalPrice, addItemToCart, removeItemFromCart, clearCart } = useCart();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  /********************************************************************************* */
  /*****************************     LOAD DTA     ********************************** */
  /********************************************************************************* */
  const [bannerImage, setBannerImage] = useState([['/assets/images/banner_long_1__2500__1251__.jpg']]);
  const [bannerImage2, setBannerImage2] = useState([['/assets/images/mbanner_1_1.jpeg']]);
  const [businessData, setBusinessData] = useState();
  const [productData, setProductData] = useState();
  const [productData2, setProductData2] = useState();
  const [webLogo, setWebLogo] = useState();
  const [shopfrontData, setShopfrontData] = useState();
  const [categoryData, setCategoryData] = useState();
  const [mainColor, setMainColor] = useState();

  //const [oneProduct, setOneProduct] = useState(null);

  useEffect(() => {
    console.log("slug === " + slug);

    const fetchAllWebData = async (slug) => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/businesses/getShopfrontMainBannerImageURL/` + slug);
        if (response.data) {
          setBusinessData(response.data.businessData);

          //const sortedData = [...data].sort((a, b) => a.order - b.order); //ในกรณีอยาก copy
          (response.data.shopfrontData[0].layouts).sort((a, b) => a.order - b.order);

          const data = response.data.shopfrontData[0].layouts;
          const extractImageUrls = key => data.map(item => item.content.map(content => content[key]));

          setBannerImage(extractImageUrls("imageUrl"));
          setBannerImage2(extractImageUrls("imageUrl2"));
          (response.data.productData).sort((a, b) => {
            const dateA = new Date(a.lastUpdate);
            const dateB = new Date(b.lastUpdate);
            return dateB - dateA;
          });
          setProductData(response.data.productData);
          setProductData2(response.data.productData);
          setShopfrontData(response.data.shopfrontData[0]);
          //alert(JSON.stringify(response.data.shopfrontData, null, 2));
          setCategoryData(response.data.shopfrontData[0]);
          setWebLogo(response.data.shopfrontData[0].logoImageURL);

          //alert(response.data.shopfrontData[0].logoImageURL);
        } else {
          //await alert("error1 ---- " + response.data);
        }

      } catch (error) {
        //await alert("error2 ---- " + error);
        console.error("Failed to fetch banner image:", error);
      }
    };

    fetchAllWebData(slug);

  }, [slug]);

  useEffect(() => {
    if (shopfrontData) {
      const colorR = shopfrontData.mainColor;
      //alert(JSON.stringify(shopfrontData, null, 2));
      const rgbaString = `rgba(${colorR[0]}, ${colorR[1]}, ${colorR[2]}, ${colorR[3] / 255})`;
      //setBannerImage(shopfrontData.layouts[0].content[0].imageUrl);
      setMainColor(rgbaString);
    }
  }, [shopfrontData]);

  useEffect(() => {
    console.log(bannerImage);
  }, [bannerImage]);


  /********************************************************************************* */
  /*********************************   Save Order  ********************************* */
  /********************************************************************************* */

  const handleClick = () => {
    setLoading(true);
    setTimeout(async () => {
      setLoading(false);
      await saveOrder();
    }, 2000);
  };

  const saveOrder = async (status = 'paid') => {
    console.log(JSON.stringify(cart, null, 2));

    var productInCart = [];

    for (let i = 0; i < cart.items.length; i++) {
      (cart.items.name);
      productInCart.push({
        'name': cart.items[i].name,
        'price': cart.items[i].variants[0].price,
        'brand': cart.items[i].name,
        'imageURL': cart.items[i].imageUrls[0],
        'num': cart.items[i].quantity,
        'headerName1': cart.items[i].variantsOptions[0] != null ? cart.items[i].variantsOptions[0].name : "",
        'headerName2': cart.items[i].variantsOptions[1] != null ? cart.items[i].variantsOptions[1].name : "",
        'productId': cart.items[i].productId,
        'variantIndex': 0,
        'productIndex': 0,
      });
    }

    const passData = {
      'businessId': businessData.businessId,
      'employeeId': 'shopfront',
      'isGift': false,
      'lastUpdateAt': Date.now().toString(),
      'orderItems': productInCart,
      'paymentStatus': status,
      'subtotalPrice': getTotalPrice(),
      'totalPrice': getTotalPrice(),
    };
    console.log('passData =', passData);

    try {
      // const headers = {
      //   'Content-Type': 'application/json',
      //   'Authorization': accessToken,
      //   'Hardware-ID': deviceId,
      //   'Mac-Address': macAddress,
      // };
      const headers = {
        'Content-Type': 'application/json',
        'role': 'superadmin',
        'x-super-admin-api-key': 'TempAPIkey0123456789',
      };
      console.log('Saving Order: new Order Save');
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/orders`, passData, { headers: headers });

      if (response.data) {
        var orderData = response.data.data;
        //alert(JSON.stringify(orderData, null, 2));
        console.log('Order saved:', response.data);
        clearCart();
        navigate(`/${slug}/order-placed/${orderData.orderId}`);
      }
    } catch (e) {
      console.error('Error Making API Request:', e);
    }
  };
  /********************************************************************************* */
  /********************************************************************************* */
  /********************************************************************************* */

  return (
    <>
      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
      <Layout childrenClasses="pt-0 pb-0" webLogo={webLogo} slug={slug} mainColor={mainColor}>
        <div className="checkout-page-wrapper w-full bg-white pb-[60px]">
          {/* <div className="w-full mb-5">
          <PageTitle
            title="Checkout"
            breadcrumb={[
              { name: "home", path: `/${slug}` },
              { name: "checkout", path: `/${slug}/checkout` },
            ]}
          />
        </div> */}
          <div className="checkout-main-content w-full">
            <div className="container-x mx-auto">
              <div className="w-full sm:mb-10 mb-5">
                <div className="sm:flex sm:space-x-[18px] s">
                  <div className="sm:w-1/2 w-full mb-5 h-[70px]">
                    <a href="#">
                      <div className="w-full h-full bg-[#F6F6F6] text-qblack flex justify-center items-center">
                        <span className="text-[15px] font-medium">
                          Log into your Account
                        </span>
                      </div>
                    </a>
                  </div>
                  <div className="flex-1 h-[70px]">
                    <a href="#">
                      <div className="w-full h-full bg-[#F6F6F6] text-qblack flex justify-center items-center">
                        <span className="text-[15px] font-medium">
                          Enter Coupon Code
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="w-full lg:flex lg:space-x-[30px]">
                <div className="lg:w-1/2 w-full">
                  <h1 className="sm:text-2xl text-xl text-qblack font-medium mb-5">
                    Billing Details
                  </h1>
                  <div className="form-area">
                    <form>
                      <div className="sm:flex sm:space-x-5 items-center mb-6">
                        <div className="sm:w-1/2  mb-5 sm:mb-0">
                          <InputCom
                            label="First Name*"
                            placeholder="Demo Name"
                            inputClasses="w-full h-[50px]"
                          />
                        </div>
                        <div className="flex-1">
                          <InputCom
                            label="Last Name*"
                            placeholder="Demo Name"
                            inputClasses="w-full h-[50px]"
                          />
                        </div>
                      </div>
                      <div className="flex space-x-5 items-center mb-6">
                        <div className="w-1/2">
                          <InputCom
                            label="Email Address*"
                            placeholder="demoemial@gmail.com"
                            inputClasses="w-full h-[50px]"
                          />
                        </div>
                        <div className="flex-1">
                          <InputCom
                            label="Phone Number*"
                            placeholder="012 3  *******"
                            inputClasses="w-full h-[50px]"
                          />
                        </div>
                      </div>
                      <div className="mb-6">
                        <h1 className="input-label capitalize block  mb-2 text-qgray text-[13px] font-normal">
                          Country*
                        </h1>
                        <div className="w-full h-[50px] border border-[#EDEDED] px-5 flex justify-between items-center mb-2">
                          <span className="text-[13px] text-qgraytwo">
                            Select Country
                          </span>
                          <span>
                            <svg
                              width="11"
                              height="7"
                              viewBox="0 0 11 7"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.4 6.8L0 1.4L1.4 0L5.4 4L9.4 0L10.8 1.4L5.4 6.8Z"
                                fill="#222222"
                              ></path>
                            </svg>
                          </span>
                        </div>
                      </div>
                      <div className=" mb-6">
                        <div className="w-full">
                          <InputCom
                            label="Address*"
                            placeholder="your address here"
                            inputClasses="w-full h-[50px]"
                          />
                        </div>
                      </div>
                      <div className="flex space-x-5 items-center mb-6">
                        <div className="w-1/2">
                          <h1 className="input-label capitalize block  mb-2 text-qgray text-[13px] font-normal">
                            Town / City*
                          </h1>
                          <div className="w-full h-[50px] border border-[#EDEDED] px-5 flex justify-between items-center">
                            <span className="text-[13px] text-qgraytwo">
                              Miyami Town
                            </span>
                            <span>
                              <svg
                                width="11"
                                height="7"
                                viewBox="0 0 11 7"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5.4 6.8L0 1.4L1.4 0L5.4 4L9.4 0L10.8 1.4L5.4 6.8Z"
                                  fill="#222222"
                                ></path>
                              </svg>
                            </span>
                          </div>
                        </div>
                        <div className="flex-1">
                          <InputCom
                            label="Postcode / ZIP*"
                            placeholder=""
                            inputClasses="w-full h-[50px]"
                          />
                        </div>
                      </div>
                      <div className="flex space-x-2 items-center mb-10">
                        <div>
                          <input type="checkbox" name="" id="create" />
                        </div>
                        <label
                          htmlFor="create"
                          className="text-qblack text-[15px] select-none"
                        >
                          Create an account?
                        </label>
                      </div>
                      <div>
                        <h1 className="text-2xl text-qblack font-medium mb-3">
                          Billing Details
                        </h1>
                        <div className="flex space-x-2 items-center mb-10">
                          <div>
                            <input type="checkbox" name="" id="address" />
                          </div>
                          <label
                            htmlFor="address"
                            className="text-qblack text-[15px] select-none"
                          >
                            Ship to a different address
                          </label>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="flex-1">
                  <h1 className="sm:text-2xl text-xl text-qblack font-medium mb-5">
                    Order Summary
                  </h1>

                  <div className="w-full px-10 py-[30px] border border-[#EDEDED]">
                    <div className="sub-total mb-6">
                      <div className=" flex justify-between mb-5">
                        <p className="text-[13px] font-medium text-qblack uppercase">
                          PROduct
                        </p>
                        <p className="text-[13px] font-medium text-qblack uppercase">
                          total
                        </p>
                      </div>
                      <div className="w-full h-[1px] bg-[#EDEDED]"></div>
                    </div>
                    {/******************************************************************** */}
                    {cart.items.map((item, index) => (

                      <div className="product-list w-full mb-[30px]">
                        <ul className="flex flex-col space-y-5">
                          <li>
                            <div className="flex justify-between items-center">
                              <div>
                                <h4 className="text-[15px] text-qblack mb-2.5">
                                  {item.name}
                                  <sup className="text-[14px] text-qgray ml-2 mt-2" style={{ color: '#FF8800' }}>
                                    x {item.quantity}
                                  </sup>
                                </h4>
                                {/*<p className="text-[13px] text-qgray">
                                64GB, Black, 44mm, Chain Belt
                              </p>*/}
                              </div>
                              <div>
                                <span className="text-[15px] text-qblack font-medium">
                                  ฿{(item.variants[0].price).toLocaleString()}
                                </span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    ))}
                    {/******************************************************************** */}
                    <div className="w-full h-[1px] bg-[#EDEDED]"></div>

                    <div className="mt-[30px]">
                      <div className=" flex justify-between mb-5">
                        <p className="text-[13px] font-medium text-qblack uppercase">
                          SUBTOTAL
                        </p>
                        <p className="text-[15px] font-medium text-qblack uppercase">
                          ฿{getTotalPrice()}
                        </p>
                      </div>
                    </div>

                    <div className="w-full mt-[30px]">
                      <div className="sub-total mb-6">
                        <div className=" flex justify-between mb-5">
                          <div>
                            <span className="text-xs text-qgraytwo mb-3 block">
                              SHIPPING
                            </span>
                            <p className="text-base font-medium text-qblack">
                              Free Shipping
                            </p>
                          </div>
                          <p className="text-[15px] font-medium text-qblack">
                            +฿0
                          </p>
                        </div>
                        <div className="w-full h-[1px] bg-[#EDEDED]"></div>
                      </div>
                    </div>

                    <div className="mt-[30px]">
                      <div className=" flex justify-between mb-5">
                        <p className="text-2xl font-medium text-qblack">Total</p>
                        <p className="text-2xl font-medium text-qred">฿{getTotalPrice().toLocaleString()}</p>
                      </div>
                    </div>
                    <div className="shipping mt-[30px]">
                      <ul className="flex flex-col space-y-1">
                        <li className=" mb-5">
                          <div className="flex space-x-2.5 items-center mb-4">
                            <div className="input-radio">
                              <input
                                type="radio"
                                name="price"
                                className="accent-pink-500"
                                id="transfer"
                              />
                            </div>
                            <label
                              htmlFor="transfer"
                              className="text-[18px] text-normal text-qblack"
                            >
                              Direct Bank Transfer
                            </label>
                          </div>
                          <p className="text-qgraytwo text-[15px] ml-6">
                            Make your payment directly into our bank account.
                            Please use your Order ID as the payment reference.
                          </p>
                        </li>
                        <li>
                          <div className="flex space-x-2.5 items-center mb-5">
                            <div className="input-radio">
                              <input
                                type="radio"
                                name="price"
                                className="accent-pink-500"
                                id="delivery"
                              />
                            </div>
                            <label
                              htmlFor="delivery"
                              className="text-[18px] text-normal text-qblack"
                            >
                              Cash on Delivery
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="flex space-x-2.5 items-center mb-5">
                            <div className="input-radio">
                              <input
                                type="radio"
                                name="price"
                                className="accent-pink-500"
                                id="bank"
                              />
                            </div>
                            <label
                              htmlFor="bank"
                              className="text-[18px] text-normal text-qblack"
                            >
                              Credit/Debit Cards or Paypal
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <a onClick={() => { handleClick() }}>
                      <div className="w-full h-[50px] black-btn flex justify-center items-center">
                        <span className="text-sm font-semibold">
                          Place Order Now
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
