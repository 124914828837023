import AOS from "aos";
import "aos/dist/aos.css";
import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";

AOS.init();
var langTH = "Sdsd"
const rootElement = document.getElementById("root");
window.myGlobalVariable = "Hello, World!";
ReactDOM.render(
  <StrictMode>

      <App />

  </StrictMode>,
  rootElement
);
