import axios from 'axios';
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useCart } from '../../CartContext';
import BreadcrumbCom from "../BreadcrumbCom";
import EmptyCardError from "../EmptyCardError";
import InputCom from "../Helpers/InputCom";
import Layout from "../Partials/Layout";
import ProductsTable from "./ProductsTable";


export default function CardPage() {
  const { cart, getTotalQuantity, getTotalPrice, addItemToCart, removeItemFromCart } = useCart();
  useEffect(() => {
  }, []);

  const removeFromCart = (productId) => {
    removeItemFromCart(productId);
  };
  const { slug } = useParams();

  /********************************************************************************* */
  /*****************************     LOAD DTA     ********************************** */
  /********************************************************************************* */
  const [bannerImage, setBannerImage] = useState([['/assets/images/banner_long_1__2500__1251__.jpg']]);
  const [bannerImage2, setBannerImage2] = useState([['/assets/images/mbanner_1_1.jpeg']]);
  const [businessData, setBusinessData] = useState();
  const [productData, setProductData] = useState();
  const [productData2, setProductData2] = useState();
  const [webLogo, setWebLogo] = useState();
  const [shopfrontData, setShopfrontData] = useState();
  const [categoryData, setCategoryData] = useState();
  const [oneProduct, setOneProduct] = useState(null);

  useEffect(() => {
    console.log("slug === " + slug);

    const fetchAllWebData = async (slug) => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/businesses/getShopfrontMainBannerImageURL/` + slug);
        if (response.data) {
          setBusinessData(response.data.businessData);

          //const sortedData = [...data].sort((a, b) => a.order - b.order); //ในกรณีอยาก copy
          (response.data.shopfrontData[0].layouts).sort((a, b) => a.order - b.order);

          const data = response.data.shopfrontData[0].layouts;
          const extractImageUrls = key => data.map(item => item.content.map(content => content[key]));

          setBannerImage(extractImageUrls("imageUrl"));
          setBannerImage2(extractImageUrls("imageUrl2"));
          setWebLogo(response.data.shopfrontData[0].logoImageURL);
          setShopfrontData(response.data.shopfrontData[0]);
          setCategoryData(response.data.categoryData[0]);
          setProductData(response.data.productData);
          setProductData2(response.data.productData);
          //alert(response.data.shopfrontData[0].logoImageURL);
        }
      } catch (error) {
        console.error("Failed to fetch banner image:", error);
      }
    };

    fetchAllWebData(slug);

  }, [slug]);

  useEffect(() => {
    console.log(bannerImage);
  }, [bannerImage]);

  useEffect(() => {
    if (shopfrontData) {
      //alert(JSON.stringify(shopfrontData, null, 2));
      if (shopfrontData.layouts[0]) {
        if (shopfrontData.layouts[0].content[0].imageUrl != "") {
          //setBannerImage(shopfrontData.layouts[0].content[0].imageUrl);
        }
      }
    }
  }, [shopfrontData]);

  // useEffect(() => {
  //   if (productData) {
  //     const foundProduct = productData.find(item => item.productId === productId);
  //     setOneProduct(foundProduct);
  //   }
  // }, [productData]);
  /********************************************************************************* */
  /******************************** FROM ENDPOINT  ********************************* */
  /********************************************************************************* */

  return (
    <Layout childrenClasses={cart ? "pt-0 pb-0" : ""} slug={slug} webLogo={webLogo}>

      {cart === false ? (
        <div className="cart-page-wrapper w-full">
          <div className="container-x mx-auto">
            <BreadcrumbCom
              paths={[
                { name: "home", path: "/" + slug },
                { name: "cart", path: "/" + slug + "/cart" },
              ]}
            />
            <EmptyCardError />
          </div>
        </div>
      ) : (
        <div className="cart-page-wrapper w-full bg-white pb-[60px]">
          {/* <div className="w-full">
            <PageTitle
              title="Yout Cart"
              breadcrumb={[
                { name: "home", path: `/${slug}` },
                { name: "cart", path: `/${slug}/cart` },
              ]}
            />
            </div> */}

          <div className="w-full mt-[23px]">
            <div className="container-x mx-auto">

              <ProductsTable className="mb-[10px]" />

              <div style={{ width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }} >
                <div style={{ minWidth: '370px', flexGrow: '1', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <div className="discount-code sm:w-[270px] w-full mb-5 sm:mb-0 h-[50px] flex">
                    <div className="flex-1 h-full">
                      <InputCom type="text" placeholder="Discount Code" />
                    </div>
                    <button type="button" className="w-[90px] h-[50px] black-btn">
                      <span className="text-sm font-semibold">Apply</span>
                    </button>
                  </div>
                  <div
                  //className="flex space-x-2.5 items-center"
                  >

                    {/*<a href="#">
                      <div className="w-[140px] h-[50px] bg-[#F6F6F6] flex justify-center items-center">
                        <span className="text-sm font-semibold">Update Cart</span>
                      </div>
                    </a>*/}
                  </div>
                </div>

                <div className="sm:w-[370px]  mt-[30px]  sm:justify-end" style={{ width: '370px', flexGrow: '0', flexShrink: '0', margin: '0px' }}>
                  <a href={`/${slug}`}>
                    <div className="w-full h-[50px] bg-[#F6F6F6] flex justify-center items-center mb-2" style={{}}>
                      <span className="text-sm font-semibold">
                        Continue Shopping
                      </span>
                    </div>
                  </a>
                  <div className="sm:w-[370px] w-full border border-[#EDEDED] px-[30px] py-[26px]" style={{ margin: '0px' }}>
                    <div className="sub-total mb-6">
                      <div className=" flex justify-between mb-6">
                        <p className="text-[15px] font-medium text-qblack">
                          Subtotal
                        </p>
                        <p className="text-[15px] font-medium text-qred" style={{ fontSize: '20px' }}>฿{getTotalPrice().toLocaleString()}</p>
                      </div>
                      <div className="w-full h-[1px] bg-[#EDEDED]"></div>
                    </div>
                    <div className="shipping mb-6">
                      <span className="text-[15px] font-medium text-qblack mb-[18px] block">
                        Shipping
                      </span>
                      {/****************************************************************** */}
                      <ul className="flex flex-col space-y-1">
                        <li>
                          <div className="flex justify-between items-center">
                            <div className="flex space-x-2.5 items-center">
                              <div className="input-radio">
                                <input
                                  type="radio"
                                  name="price"
                                  className="accent-pink-500"
                                />
                              </div>
                              <span className="text-[13px] text-normal text-qgraytwo">
                                Free Shipping
                              </span>
                            </div>
                            <span className="text-[13px] text-normal text-qgraytwo">
                              +$00.00
                            </span>
                          </div>
                        </li>
                        <li>
                          <div className="flex justify-between items-center">
                            <div className="flex space-x-2.5 items-center">
                              <div className="input-radio">
                                <input
                                  type="radio"
                                  name="price"
                                  className="accent-pink-500"
                                />
                              </div>
                              <span className="text-[13px] text-normal text-qgraytwo">
                                Flat Rate
                              </span>
                            </div>
                            <span className="text-[13px] text-normal text-qgraytwo">
                              +$00.00
                            </span>
                          </div>
                        </li>
                        <li>
                          <div className="flex justify-between items-center">
                            <div className="flex space-x-2.5 items-center">
                              <div className="input-radio">
                                <input
                                  type="radio"
                                  name="price"
                                  className="accent-pink-500"
                                />
                              </div>
                              <span className="text-[13px] text-normal text-qgraytwo">
                                Local Delivery
                              </span>
                            </div>
                            <span className="text-[13px] text-normal text-qgraytwo">
                              +$00.00
                            </span>
                          </div>
                        </li>
                      </ul>
                      {/****************************************************************** */}
                    </div>
                    {/* <div className="shipping-calculation w-full mb-3">
                      <div className="title mb-[17px]">
                        <h1 className="text-[15px] font-medium">
                          Calculate Shipping
                        </h1>
                      </div>
                      <div className="w-full h-[50px] border border-[#EDEDED] px-5 flex justify-between items-center mb-2">
                        <span className="text-[13px] text-qgraytwo">
                          Select Country
                        </span>
                        <span>
                          <svg
                            width="11"
                            height="7"
                            viewBox="0 0 11 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.4 6.8L0 1.4L1.4 0L5.4 4L9.4 0L10.8 1.4L5.4 6.8Z"
                              fill="#222222"
                            />
                          </svg>
                        </span>
                      </div>
                      <div className="w-full h-[50px]">
                        <InputCom
                          inputClasses="w-full h-full"
                          type="text"
                          placeholder="Postcode / ZIP"
                        />
                      </div>
                    </div> */}
                    {/*<button type="button" className="w-full mb-10">
                      <div className="w-full h-[50px] bg-[#F6F6F6] flex justify-center items-center">
                        <span className="text-sm font-semibold">Update Cart</span>
                      </div>
                    </button>*/}
                    <div className="total mb-6">
                      <div className=" flex justify-between">
                        <p className="text-[18px] font-medium text-qblack">
                          Total
                        </p>
                        <p className="text-[18px] font-medium text-qred" style={{ fontSize: '24px' }}>฿{getTotalPrice().toLocaleString()}</p>
                      </div>
                    </div>
                    <a href={`/${slug}/checkout`}>
                      <div className="w-full h-[50px] black-btn flex justify-center items-center">
                        <span className="text-sm font-semibold">
                          Proceed to Checkout
                        </span>
                      </div>
                    </a>
                  </div>


                </div>
              </div>

            </div>
          </div>
        </div>
      )
      }
    </Layout >
  );
}
