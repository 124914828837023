import { useEffect, useState } from "react";
import DiscountBanner from "../Home/DiscountBanner";
import Drawer from "../Mobile/Drawer";
import Footer from "./Footers/Footer";
import Header from "./Headers/HeaderOne";

export default function Layout({ children, childrenClasses, slug, webLogo, mainColor }) {
  const [drawer, setDrawer] = useState(false);
  useEffect(() => {
    //alert('ss');
  }, [])
  return (
    <>
      <Drawer open={drawer} action={() => setDrawer(!drawer)} slug={slug} webLogo={webLogo} mainColor={mainColor} />
      <div className="w-full overflow-x-hidden">
        <Header drawerAction={() => setDrawer(!drawer)} slug={slug} webLogo={webLogo} mainColor={mainColor} />
        <div className={`w-full  ${childrenClasses || "pt-[30px] pb-[60px]"}`}>
          {children && children}
        </div>
        <DiscountBanner slug={slug} webLogo={webLogo} mainColor={mainColor} />
        <Footer slug={slug} webLogo={webLogo} mainColor={mainColor} />
      </div>
    </>
  );
}
