import React from "react";

export default function PasswordSvg() {
  return (
    <svg
      width="311"
      height="320"
      viewBox="0 0 311 320"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.9609 15.5003C42.9344 63.9537 39.6038 231.09 41.1933 290.317C41.3615 296.591 45.661 301.627 51.0247 301.833C77.5522 302.848 174.8 305.422 218.189 303.293C227.226 302.849 234.413 294.286 234.601 283.707C235.503 232.848 237.493 89.8032 232.764 24.2731C232.197 16.4217 226.93 10.1698 220.228 9.38553C191.339 6.00406 93.6731 -2.25993 56.186 0.586175C49.4263 1.09942 44.1279 7.57699 43.9609 15.5003Z"
        fill="#3EA2E5"
      />
      <path
        d="M55.797 30.3671C54.369 69.4769 49.8648 199.585 51.295 247.695C51.5041 254.727 56.2417 260.414 62.2458 260.824C86.9681 262.516 171.314 266.249 207.575 262.852C214.105 262.24 219.228 256.062 219.581 248.411C221.419 208.511 226.695 87.3622 225.324 39.7903C225.105 32.2011 220.08 26.0034 213.616 25.3242C186.87 22.5146 97.2431 15.8302 66.4293 17.7969C60.6452 18.1675 56.0448 23.5964 55.797 30.3671Z"
        fill="white"
      />
      <path
        d="M122.112 10.1991C122.112 11.6858 123.102 12.9125 124.372 12.9899C130.637 13.3738 148.288 14.46 156.175 14.9954C157.731 15.1016 158.952 13.4656 158.657 11.6758L158.607 11.3705C158.405 10.1494 157.539 9.23016 156.481 9.11733C151.149 8.54546 135.059 6.96036 124.399 7.40613C123.118 7.45923 122.112 8.70032 122.112 10.1991Z"
        fill="url(#paint0_linear_116_1151)"
      />
      <path
        d="M134.44 112.39C154.875 112.39 171.44 95.8239 171.44 75.3891C171.44 54.9543 154.875 38.3887 134.44 38.3887C114.005 38.3887 97.4395 54.9543 97.4395 75.3891C97.4395 95.8239 114.005 112.39 134.44 112.39Z"
        fill="url(#paint1_linear_116_1151)"
      />
      <path
        d="M145.769 61.4069C145.769 67.6721 140.69 74.4362 134.425 74.4362C128.159 74.4362 123.08 67.6721 123.08 61.4069C123.08 55.1417 128.159 50.6973 134.425 50.6973C140.69 50.6973 145.769 55.1417 145.769 61.4069Z"
        fill="white"
      />
      <path
        d="M120.347 98.5602H148.04C150.955 98.5602 153.537 96.6765 154.425 93.9001L155.915 89.2421C157.006 85.8319 155.232 82.1396 151.87 80.9106C140.599 76.7903 128.946 77.319 117.009 81.2911C113.665 82.4039 111.809 85.9812 112.731 89.3826L113.878 93.6125C114.67 96.5327 117.32 98.5602 120.347 98.5602Z"
        fill="white"
      />
      <g opacity="0.5">
        <path
          opacity="0.65"
          d="M205.407 171.483H70.3673C69.9547 171.483 69.6206 171.149 69.6206 170.737V151.224C69.6206 150.812 69.9547 150.478 70.3673 150.478H205.407C205.82 150.478 206.154 150.812 206.154 151.224V170.737C206.154 171.148 205.82 171.483 205.407 171.483Z"
          fill="#FFBB38"
        />
        <path
          opacity="0.65"
          d="M204.671 205.757H69.6309C69.2183 205.757 68.8843 205.423 68.8843 205.01V185.498C68.8843 185.085 69.2183 184.751 69.6309 184.751H204.671C205.084 184.751 205.418 185.085 205.418 185.498V205.01C205.418 205.421 205.084 205.757 204.671 205.757Z"
          fill="#FFBB38"
        />
        <path
          opacity="0.65"
          d="M178.306 246.63H91.9473C91.4385 246.63 91.0259 246.218 91.0259 245.709V220.772C91.0259 220.263 91.4385 219.851 91.9473 219.851H178.306C178.815 219.851 179.227 220.263 179.227 220.772V245.709C179.227 246.218 178.815 246.63 178.306 246.63Z"
          fill="#FFBB38"
        />
      </g>
      <path
        opacity="0.75"
        d="M155.663 166.906H76.9963C76.2629 166.906 75.6689 166.312 75.6689 165.579V156.564C75.6689 155.83 76.2629 155.236 76.9963 155.236H155.664C156.397 155.236 156.991 155.83 156.991 156.564V165.579C156.99 166.311 156.396 166.906 155.663 166.906Z"
        fill="white"
      />
      <g opacity="0.75">
        <path
          d="M80.3307 199.101C82.7752 198.088 83.936 195.286 82.9235 192.841C81.9109 190.397 79.1084 189.236 76.6639 190.249C74.2194 191.261 73.0586 194.064 74.0712 196.508C75.0838 198.953 77.8862 200.113 80.3307 199.101Z"
          fill="white"
        />
        <path
          d="M91.6379 199.707C94.2837 199.707 96.4286 197.562 96.4286 194.917C96.4286 192.271 94.2837 190.126 91.6379 190.126C88.992 190.126 86.8472 192.271 86.8472 194.917C86.8472 197.562 88.992 199.707 91.6379 199.707Z"
          fill="white"
        />
        <path
          d="M108.946 196.644C109.554 194.069 107.959 191.489 105.384 190.881C102.809 190.273 100.229 191.867 99.6209 194.442C99.0129 197.017 100.607 199.597 103.182 200.205C105.757 200.813 108.338 199.219 108.946 196.644Z"
          fill="white"
        />
        <path
          d="M117.434 200.566C120.08 200.566 122.225 198.421 122.225 195.775C122.225 193.129 120.08 190.984 117.434 190.984C114.788 190.984 112.644 193.129 112.644 195.775C112.644 198.421 114.788 200.566 117.434 200.566Z"
          fill="white"
        />
        <path
          d="M130.578 200.445C133.224 200.445 135.369 198.3 135.369 195.654C135.369 193.008 133.224 190.863 130.578 190.863C127.932 190.863 125.788 193.008 125.788 195.654C125.788 198.3 127.932 200.445 130.578 200.445Z"
          fill="white"
        />
        <path
          d="M143.723 200.691C146.369 200.691 148.514 198.546 148.514 195.9C148.514 193.254 146.369 191.109 143.723 191.109C141.077 191.109 138.932 193.254 138.932 195.9C138.932 198.546 141.077 200.691 143.723 200.691Z"
          fill="white"
        />
      </g>
      <path
        d="M198.433 211.538L213.11 215.207L209.441 242.728C209.441 242.728 214.945 248.232 216.779 255.57C218.614 262.908 220.305 293.722 220.305 293.722L223.788 295.827L224.211 297.585L214.079 311.208L215.759 300.365C216.026 298.643 215.563 295.152 215.709 295.079C210.205 280.402 198.433 239.057 198.433 239.057V211.538Z"
        fill="url(#paint2_linear_116_1151)"
      />
      <path
        d="M194.144 297.772C194.303 297.77 194.459 297.782 194.618 297.772C193.909 288.924 190.668 249.982 187.424 235.387C183.755 218.875 192.928 185.852 192.928 185.852L209.441 202.363L200.267 233.552C206.553 242.982 199.242 298.203 199.399 298.179L199.389 298.302L201.783 301.593L201.492 303.377L186.87 312.006L192.623 302.662C193.536 301.18 194.041 299.499 194.144 297.772Z"
        fill="url(#paint3_linear_116_1151)"
      />
      <path
        d="M212.715 112.62C212.715 112.62 244.864 148.854 212.715 167.432L209.44 165.06C209.44 165.06 225.4 149.777 209.44 131.066L212.715 112.62Z"
        fill="url(#paint4_linear_116_1151)"
      />
      <path
        d="M185.906 313.362H190.572C191.446 313.362 192.269 312.961 192.802 312.269C194.257 310.378 197.271 306.69 198.713 306.594C200.653 306.465 200.405 313.362 200.405 313.362H201.492L202.869 304.791C203.216 302.635 202.144 300.498 200.207 299.488C200.207 299.488 199.763 302.768 197.359 304.218C194.954 305.669 187.574 310.864 187.574 310.864C187.574 310.864 186.67 310.734 185.677 310.915C184.204 311.181 184.409 313.362 185.906 313.362Z"
        fill="url(#paint5_linear_116_1151)"
      />
      <path
        d="M213.717 312.829L218.017 311.02C218.822 310.681 219.426 309.992 219.649 309.147C220.257 306.841 221.604 302.272 222.895 301.625C224.633 300.754 227.079 307.207 227.079 307.207L228.081 306.785L226.027 298.351C225.51 296.23 223.693 294.675 221.517 294.495C221.517 294.495 222.38 297.69 220.726 299.959C219.072 302.229 214.284 309.88 214.284 309.88C214.284 309.88 213.401 310.113 212.555 310.662C211.302 311.48 212.337 313.41 213.717 312.829Z"
        fill="url(#paint6_linear_116_1151)"
      />
      <path
        d="M188.723 157.932C188.723 157.932 189.263 161.239 188.472 170.33C187.681 179.422 183.902 200.767 184.672 221.19C184.672 221.19 195.415 223.693 214.079 222.507C214.079 222.507 221.956 190.621 220.037 178.499C218.118 166.377 207.709 157.682 207.709 157.682L188.723 157.932Z"
        fill="url(#paint7_linear_116_1151)"
      />
      <path
        d="M196.661 97.1826L195.348 112.621H207.822L204.72 89.0547L196.661 97.1826Z"
        fill="url(#paint8_linear_116_1151)"
      />
      <path
        d="M211.517 110.485C207.212 110.247 197.587 109.808 190.767 110.18C187.177 110.376 184.169 112.951 183.435 116.472L181.771 124.442C181.151 127.411 181.244 130.484 182.041 133.411L188.721 157.933C188.721 157.933 194.663 161.504 207.707 157.683C207.707 157.683 219.162 130.366 214.473 112.891C214.11 111.533 212.921 110.563 211.517 110.485Z"
        fill="url(#paint9_linear_116_1151)"
      />
      <path
        d="M183.761 115.042C181.924 119.883 180.263 124.958 178.481 129.712C177.574 132.091 176.655 134.423 175.672 136.564C175.174 137.631 174.628 138.611 174.127 139.5C173.624 140.379 173.059 141.112 172.764 141.449C172.706 141.499 172.9 141.538 172.964 141.54C173.003 141.544 171.722 141.75 171.329 141.815L169.794 141.806C167.593 141.693 165.099 141.305 162.599 140.82C157.555 139.879 152.312 138.559 146.928 137.56L146.887 137.552C145.857 137.361 144.866 138.041 144.676 139.072C144.549 139.751 144.805 140.415 145.287 140.842C147.459 142.753 149.759 144.323 152.146 145.85C153.348 146.585 154.57 147.305 155.813 147.996C157.081 148.642 158.351 149.293 159.67 149.896C162.327 151.054 165.073 152.12 168.235 152.805C169.066 152.944 169.887 153.09 170.742 153.204C171.701 153.237 172.571 153.322 173.619 153.262C174.175 153.206 174.734 153.138 175.291 153.057C175.589 153.005 175.807 152.999 176.167 152.9C176.549 152.785 176.931 152.656 177.307 152.516C178.092 152.234 178.6 151.893 179.238 151.538C179.768 151.189 180.251 150.824 180.719 150.45C182.448 148.977 183.521 147.515 184.468 146.113C185.411 144.711 186.159 143.306 186.831 141.929C188.178 139.179 189.229 136.455 190.083 133.712C191.812 128.214 192.983 122.811 193.477 117.072C193.714 114.323 191.678 111.903 188.929 111.666C186.654 111.47 184.604 112.831 183.831 114.865L183.761 115.042Z"
        fill="url(#paint10_linear_116_1151)"
      />
      <path
        d="M148.622 140.026L146.397 134.742L134.681 131.823C134.681 131.823 133.744 133.854 138.664 134.742C138.664 134.742 136.906 136.685 137.844 138.97C138.781 141.254 148.74 143.544 148.622 140.026Z"
        fill="url(#paint11_linear_116_1151)"
      />
      <path
        d="M191.379 79.3506C190.934 79.6128 187.425 91.9916 190.365 97.1805C193.306 102.371 202.287 96.5578 204.721 89.0526C207.155 81.5496 203.244 72.3532 191.379 79.3506Z"
        fill="url(#paint12_linear_116_1151)"
      />
      <path
        d="M214.656 89.0538C216.67 98.9228 223.344 96.2227 228.072 100.613C235.734 107.73 246.694 100.393 257.349 107.137C265.042 112.006 268.748 124.461 265.057 134.518C259.944 148.447 242.472 154.175 233.417 149.932C224.764 145.878 225.996 133.735 214.351 129.042C209.408 127.05 206.968 128.344 204.008 125.392C198.826 120.223 200.224 110.191 200.763 106.326C201.932 97.9405 204.742 96.4229 203.4 92.7372C200.786 85.5694 188.595 87.0483 187.58 81.9877C186.655 77.3795 195.491 68.6443 203.806 70.0215C216.67 72.1508 213.757 84.6569 214.656 89.0538Z"
        fill="url(#paint13_linear_116_1151)"
      />
      <path
        opacity="0.6"
        d="M261.214 132.158C261.367 132.158 261.504 132.055 261.542 131.9C263.189 125.062 262.442 119.454 259.32 115.232C254.138 108.225 244.069 107.531 243.969 107.524C243.81 107.498 243.623 107.654 243.612 107.841C243.601 108.027 243.742 108.186 243.928 108.197C244.026 108.203 253.79 108.881 258.781 115.637C261.776 119.691 262.485 125.11 260.887 131.743C260.844 131.924 260.955 132.106 261.136 132.149C261.161 132.155 261.189 132.158 261.214 132.158Z"
        fill="white"
      />
      <path
        opacity="0.72"
        d="M228.235 138.42C228.248 138.42 228.264 138.418 228.278 138.415C228.368 138.391 228.422 138.299 228.399 138.209C226.795 132.053 223.979 127.778 220.032 125.502C217.16 123.845 214.471 123.691 212.1 123.555C209.812 123.423 207.837 123.309 206.247 121.896C202.961 118.973 203.151 111.946 206.813 101.009C206.842 100.921 206.794 100.826 206.706 100.796C206.618 100.766 206.523 100.815 206.493 100.902C202.784 111.978 202.626 119.125 206.023 122.146C207.7 123.638 209.729 123.755 212.08 123.89C214.415 124.024 217.061 124.176 219.864 125.793C223.73 128.021 226.492 132.228 228.073 138.294C228.092 138.37 228.161 138.42 228.235 138.42Z"
        fill="white"
      />
      <path
        opacity="0.72"
        d="M209.889 86.6043C209.907 86.6043 209.925 86.6021 209.943 86.5954C210.032 86.5656 210.078 86.4693 210.05 86.3819C208.774 82.5879 205.1 83.1509 201.206 83.7504C198.741 84.1298 196.194 84.5192 194.033 83.8798C192.42 83.402 191.462 82.6609 191.185 81.6775C190.712 80.0028 192.369 78.1368 192.386 78.1168C192.449 78.0483 192.442 77.941 192.373 77.879C192.305 77.8171 192.198 77.8226 192.136 77.8912C192.061 77.973 190.339 79.9132 190.86 81.7671C191.171 82.8688 192.205 83.6885 193.936 84.2006C196.171 84.8621 198.756 84.4661 201.257 84.0812C205.165 83.4816 208.544 82.9606 209.73 86.4881C209.753 86.56 209.819 86.6043 209.889 86.6043Z"
        fill="white"
      />
      <path
        d="M247.021 49.021C247.021 31.239 261.735 16.8259 279.391 16.8281C296.526 16.8304 310.049 30.4848 310.049 47.3258C310.049 64.1668 296.526 78.5482 279.391 79.4723C261.734 80.4246 247.021 66.803 247.021 49.021Z"
        fill="#27AE60"
      />
      <path
        d="M249.976 48.9447C249.976 32.8296 263.311 19.7674 279.313 19.7686C294.842 19.7708 307.098 32.1456 307.098 47.4081C307.098 62.6705 294.842 75.7044 279.313 76.5423C263.311 77.4052 249.976 65.0599 249.976 48.9447Z"
        fill="#27AE60"
      />
      <path
        d="M269.808 47.1152H267.178V36.3892C267.178 32.1767 270.259 28.6164 274.344 28.108L279.72 27.4387C282.034 27.1511 284.361 27.8928 286.105 29.4759C287.85 31.0579 288.851 33.334 288.851 35.7199V45.0837H286.222V35.7199C286.222 34.104 285.544 32.5617 284.362 31.4904C283.181 30.4192 281.603 29.9153 280.036 30.1112L274.66 30.7804C271.893 31.1247 269.806 33.5367 269.806 36.3892V47.1152H269.808Z"
        fill="white"
      />
      <path
        d="M266.4 44.7088L289.447 43.402C290.383 43.3488 291.17 44.112 291.17 45.0712V63.7625C291.17 64.6854 290.439 65.4339 289.537 65.4339H267.045C266.164 65.4339 265.441 64.7183 265.412 63.8169L264.858 46.4335C264.83 45.5241 265.514 44.7586 266.4 44.7088Z"
        fill="white"
      />
      <path
        d="M281.771 60.0776L280.376 55.9715C280.208 55.4778 280.323 54.9263 280.681 54.5538C281.396 53.8121 281.822 52.7782 281.772 51.6424C281.684 49.6392 280.085 48.0165 278.128 47.9395C275.963 47.8545 274.181 49.6267 274.181 51.8247C274.181 53.0443 274.729 54.1314 275.588 54.8448C276.032 55.214 276.216 55.8221 276.028 56.3758L274.77 60.0776C274.464 60.9779 275.118 61.9177 276.048 61.9177H280.493C281.424 61.9189 282.077 60.9779 281.771 60.0776Z"
        fill="#27AE60"
      />
      <path
        d="M71.6147 264.57C71.7164 264.335 71.6644 264.057 71.4852 263.879L66.3749 258.799C67.0087 255.419 67.1348 251.907 66.6934 248.388L72.0969 243.691C72.2861 243.526 72.3558 243.253 72.2684 243.01L69.2918 234.72C69.2044 234.478 68.9809 234.319 68.7354 234.326L61.7158 234.521C59.8342 231.571 57.5301 229.014 54.9263 226.909L55.5756 219.561C55.5988 219.304 55.4628 219.059 55.2382 218.952L47.5572 215.286C47.3326 215.179 47.0683 215.233 46.899 215.421L42.0751 220.776C38.8552 220.102 35.5102 219.962 32.1619 220.416L27.6754 214.73C27.5183 214.531 27.2584 214.458 27.0272 214.548L19.1404 217.654C18.9103 217.745 18.7599 217.98 18.7676 218.238L18.97 225.612C16.1649 227.581 13.738 229.994 11.7403 232.724L4.74284 232.025C4.49728 232.001 4.26499 232.143 4.16322 232.378L0.691044 240.438C0.589279 240.673 0.641268 240.951 0.820463 241.129L5.93084 246.208C5.29702 249.589 5.17092 253.101 5.61227 256.619L0.208766 261.318C0.019616 261.483 -0.0500705 261.756 0.0373147 261.998L3.01394 270.289C3.10133 270.531 3.32477 270.689 3.57033 270.683L10.5899 270.488C12.4715 273.438 14.7755 275.994 17.3783 278.099L16.729 285.448C16.7058 285.705 16.8418 285.95 17.0664 286.057L24.7474 289.723C24.972 289.83 25.2363 289.776 25.4056 289.588L30.2295 284.233C33.4494 284.907 36.7944 285.047 40.1427 284.593L44.6292 290.279C44.7863 290.478 45.0473 290.551 45.2774 290.461L53.1642 287.355C53.3943 287.264 53.5447 287.029 53.5381 286.77L53.3357 279.397C56.1397 277.428 58.5677 275.014 60.5654 272.284L67.5629 272.984C67.8084 273.008 68.0407 272.866 68.1425 272.631L71.6147 264.57Z"
        fill="#FFBB38"
      />
      <path
        opacity="0.7"
        d="M43.7425 248.564C45.5831 252.452 44.4836 257.279 40.9948 259.821C37.1598 262.615 31.8692 261.606 29.2 257.572C27.9058 255.618 27.4147 253.254 27.8129 250.915C28.2123 248.577 29.4545 246.543 31.3117 245.19C35.1467 242.396 40.4373 243.405 43.1065 247.439C43.3476 247.802 43.56 248.178 43.7425 248.564ZM30.3305 255.518C30.4721 255.818 30.6358 256.11 30.8216 256.39C32.868 259.482 36.9253 260.256 39.8655 258.114C42.8056 255.972 43.5334 251.713 41.486 248.62C39.4396 245.527 35.3823 244.754 32.4421 246.896C31.0174 247.933 30.065 249.493 29.7586 251.286C29.5098 252.744 29.7133 254.214 30.3305 255.518Z"
        fill="white"
      />
      <path
        opacity="0.7"
        d="M54.6196 242.7C54.9359 243.368 55.2213 244.056 55.4757 244.762C55.5554 244.985 55.6317 245.209 55.7058 245.434C56.0664 246.544 56.3418 247.683 56.5288 248.832C56.531 248.847 56.5343 248.863 56.5365 248.878C56.5432 248.923 56.5509 248.967 56.5575 249.012C56.5708 249.099 56.5841 249.186 56.5963 249.272C56.6283 249.5 56.5144 249.716 56.3286 249.813C56.2832 249.836 56.2334 249.853 56.1804 249.862C55.9105 249.904 55.6594 249.707 55.6184 249.424C55.4105 247.965 55.0521 246.519 54.551 245.126C52.0688 238.21 46.4441 233.478 40.0229 232.047C36.4523 231.252 32.635 231.479 28.9759 232.92C28.3343 233.172 27.7094 233.458 27.1032 233.773C23.0271 235.895 19.7993 239.395 17.899 243.808C15.7155 248.876 15.544 254.539 17.4156 259.752C21.2827 270.519 32.7534 275.99 42.9907 271.957C43.6223 271.708 44.2384 271.427 44.8391 271.115C47.4396 269.762 49.7271 267.826 51.5323 265.436C51.5788 265.374 51.6363 265.327 51.6994 265.295C51.8653 265.208 52.071 265.224 52.2259 265.353C52.4394 265.533 52.477 265.858 52.3066 266.082C50.4118 268.591 48.0093 270.624 45.2804 272.044C44.651 272.372 44.0028 272.667 43.3403 272.928C32.5919 277.162 20.5471 271.411 16.4909 260.115C14.5253 254.641 14.7067 248.697 16.9986 243.376C18.9941 238.744 22.3822 235.068 26.6618 232.842C27.2979 232.511 27.9538 232.212 28.6275 231.947C33.8341 229.896 39.4942 230.099 44.5659 232.52C48.9805 234.63 52.4892 238.199 54.6196 242.7Z"
        fill="white"
      />
      <path
        d="M112.331 302.649C112.398 302.492 112.364 302.306 112.244 302.187L108.825 298.788C109.25 296.526 109.333 294.175 109.039 291.821L112.655 288.677C112.782 288.567 112.827 288.384 112.77 288.223L110.778 282.676C110.719 282.513 110.57 282.408 110.405 282.412L105.707 282.543C104.448 280.568 102.906 278.858 101.164 277.45L101.599 272.533C101.614 272.361 101.524 272.197 101.373 272.125L96.233 269.672C96.0826 269.6 95.9056 269.636 95.7928 269.761L92.565 273.345C90.4103 272.894 88.1726 272.801 85.9315 273.104L82.9294 269.299C82.8244 269.165 82.6496 269.116 82.4958 269.177L77.2184 271.256C77.0647 271.317 76.964 271.474 76.9684 271.646L77.1034 276.581C75.2263 277.898 73.6025 279.513 72.2651 281.339L67.5828 280.871C67.4191 280.855 67.2632 280.95 67.1957 281.107L64.8728 286.499C64.8053 286.657 64.8396 286.842 64.9591 286.962L68.3793 290.361C67.9545 292.623 67.8715 294.974 68.1658 297.327L64.5498 300.471C64.4226 300.582 64.3772 300.764 64.4348 300.926L66.4269 306.473C66.4855 306.636 66.6349 306.741 66.7997 306.736L71.4975 306.606C72.7563 308.58 74.2982 310.29 76.0404 311.698L75.6057 316.615C75.5902 316.788 75.6809 316.952 75.8313 317.023L80.9716 319.477C81.122 319.549 81.299 319.512 81.4118 319.387L84.6395 315.803C86.7943 316.255 89.032 316.348 91.2731 316.045L94.2751 319.85C94.3802 319.983 94.555 320.032 94.7088 319.971L99.9861 317.893C100.14 317.832 100.241 317.675 100.236 317.502L100.101 312.568C101.978 311.25 103.602 309.636 104.939 307.809L109.622 308.277C109.785 308.294 109.941 308.199 110.01 308.042L112.331 302.649Z"
        fill="#FFBB38"
      />
      <path
        opacity="0.7"
        d="M93.7754 291.899C94.324 293.058 94.5043 294.364 94.2831 295.659C94.011 297.252 93.1648 298.637 91.8994 299.56C89.2889 301.462 85.6851 300.775 83.8655 298.029C82.0492 295.273 82.693 291.496 85.3046 289.593C86.57 288.671 88.102 288.322 89.6196 288.612C91.1373 288.901 92.458 289.794 93.3396 291.125C93.5033 291.373 93.6493 291.633 93.7754 291.899ZM84.7991 296.551C84.8909 296.745 84.996 296.933 85.1165 297.115C86.455 299.136 89.1053 299.641 91.0266 298.241C91.9569 297.563 92.5808 296.544 92.7799 295.373C92.9801 294.2 92.7323 293.017 92.0852 292.037C91.437 291.058 90.4658 290.401 89.3497 290.188C88.2325 289.976 87.1054 290.232 86.1751 290.911C84.4285 292.184 83.8776 294.603 84.7991 296.551Z"
        fill="white"
      />
      <path
        opacity="0.7"
        d="M101.353 287.827C101.568 288.282 101.763 288.751 101.937 289.236C102.283 290.198 102.535 291.198 102.689 292.21C102.695 292.255 102.702 292.301 102.709 292.347C102.76 292.7 102.583 293.032 102.295 293.182C102.225 293.219 102.148 293.244 102.067 293.256C101.65 293.321 101.264 293.017 101.2 292.581C101.065 291.634 100.833 290.698 100.51 289.799C100.492 289.749 100.473 289.7 100.456 289.651C99.6724 287.531 98.4147 285.694 96.8052 284.26C95.9314 283.481 94.9525 282.822 93.8872 282.301C93.8784 282.297 93.8695 282.292 93.8618 282.288C93.8352 282.276 93.8087 282.262 93.7822 282.25C90.6551 280.758 87.1641 280.632 83.9541 281.897C83.5382 282.061 83.1344 282.245 82.7418 282.449C80.1025 283.823 78.0141 286.09 76.783 288.946C75.3693 292.227 75.2587 295.892 76.471 299.267C77.6834 302.64 80.0726 305.323 83.1986 306.815C86.3257 308.308 89.8166 308.434 93.0267 307.168C93.4348 307.008 93.8341 306.825 94.2224 306.623C95.9059 305.747 97.3871 304.494 98.5552 302.946C98.6271 302.851 98.7155 302.777 98.8129 302.727C99.0695 302.594 99.387 302.619 99.627 302.818C99.9566 303.094 100.013 303.598 99.7509 303.944C98.4434 305.674 96.7864 307.078 94.9038 308.058C94.4691 308.284 94.0222 308.488 93.5653 308.668C89.9726 310.083 86.0679 309.943 82.5692 308.273C79.0716 306.603 76.3958 303.608 75.0419 299.829C73.6858 296.053 73.8097 291.952 75.3925 288.281C76.7686 285.085 79.107 282.549 82.0593 281.013C82.4973 280.785 82.9497 280.578 83.4143 280.395C90.3675 277.658 98.1083 280.975 101.353 287.827Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_116_1151"
          x1="122.138"
          y1="-175.122"
          x2="133.587"
          y2="-58.3452"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F52FF" />
          <stop offset="1" stopColor="#4042E2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_116_1151"
          x1="97.4392"
          y1="75.3893"
          x2="171.441"
          y2="75.3893"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F52FF" />
          <stop offset="1" stopColor="#4042E2" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_116_1151"
          x1="231.298"
          y1="308.783"
          x2="189.861"
          y2="202.11"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_116_1151"
          x1="231.927"
          y1="651.901"
          x2="94.973"
          y2="-948.754"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_116_1151"
          x1="227.004"
          y1="140.026"
          x2="209.44"
          y2="140.026"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_116_1151"
          x1="196.614"
          y1="311.808"
          x2="154.833"
          y2="247.705"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F52FF" />
          <stop offset="1" stopColor="#4042E2" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_116_1151"
          x1="234.261"
          y1="331.33"
          x2="187.9"
          y2="251.2"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F52FF" />
          <stop offset="1" stopColor="#4042E2" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_116_1151"
          x1="241.231"
          y1="136.281"
          x2="204.599"
          y2="185.888"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F52FF" />
          <stop offset="1" stopColor="#4042E2" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_116_1151"
          x1="207.822"
          y1="100.838"
          x2="195.348"
          y2="100.838"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_116_1151"
          x1="352.785"
          y1="83.3682"
          x2="301.653"
          y2="100.158"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF928E" />
          <stop offset="1" stopColor="#FE7062" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_116_1151"
          x1="134.633"
          y1="107.95"
          x2="242.521"
          y2="169.757"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_116_1151"
          x1="123.913"
          y1="126.662"
          x2="231.802"
          y2="188.469"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_116_1151"
          x1="187.045"
          y1="100.832"
          x2="211.287"
          y2="65.385"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEBBBA" />
          <stop offset="1" stopColor="#FF928E" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_116_1151"
          x1="199.885"
          y1="75.5063"
          x2="234.226"
          y2="120.15"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#09005D" />
          <stop offset="1" stopColor="#1A0F91" />
        </linearGradient>
      </defs>
    </svg>
  );
}
