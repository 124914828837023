import React, { useEffect, useState } from "react";
import { useCart } from "../../../../CartContext";
import Cart from "../../../Cart/index";
import SearchBox from "../../../Helpers/SearchBox";
import ThinBag from "../../../Helpers/icons/ThinBag";
import ThinLove from "../../../Helpers/icons/ThinLove";
import ThinPeople from "../../../Helpers/icons/ThinPeople";

export default function Middlebar({ className, slug, type, webLogo, mainColor }) {
  const [totalNumber, setTotalNumber] = useState(0);
  //const { slug } = useParams();
  const { cart, getTotalQuantity, addToCart } = useCart();

  const loadCartItems = async () => {
    const savedCart = localStorage.getItem('cart');
    console.log('saved cart === ' + savedCart);
    if (savedCart) {
      setCart(JSON.parse(savedCart));
    }
  };

  useEffect(() => {
    // console.log('cart === ' + JSON.stringify(cart, null, 2));
    //loadCartItems();

  }, []);

  useEffect(() => {
    console.log('cart === ' + JSON.stringify(cart, null, 2));
  }, [cart]);
  // useEffect(() => {
  //   const totalQuantity = Array.isArray(cart) ? cart.reduce((sum, product) => sum + (product.quantity || 0), 0) : 0;
  //   setTotalNumber(totalQuantity);
  // }, [cart]);


  return (
    <div className={`w-full h-[86px] bg-white ${className}`}>
      <div className="container-x mx-auto h-full">
        <div className="relative h-full">
          <div className="flex justify-between items-center h-full">
            <div>
              {type === 3 ? (
                <a href={`/${slug}`} style={{ visibility: webLogo != "" ? 'inherit' : 'hidden' }}>
                  <img
                    width="152"
                    height="36"
                    //src={`${process.env.PUBLIC_URL}/assets/images/logo1.png`}
                    src={webLogo ? `${webLogo}?sdf=${Date.now()}` : `${process.env.PUBLIC_URL}/assets/images/images/white1.gif`}
                    alt="logo"
                  />
                </a>
              ) : (
                <a href={`/${slug}`} style={{ visibility: webLogo != "" ? 'inherit' : 'hidden' }} >
                  <img
                    width="152"
                    height="36"
                    //src={`${process.env.PUBLIC_URL}/assets/images/logo1.png`}
                    src={webLogo ? `${webLogo}?sdf=${Date.now()}` : `${process.env.PUBLIC_URL}/assets/images/white1.gif`}
                    alt="logo"
                  />
                </a>
              )}

            </div>
            <div className="w-[517px] h-[44px]">
              <SearchBox type={type} className="search-com" />
            </div>
            <div className="flex space-x-6 items-center">
              {/*<div className="compaire relative">
                <a href="/products-compaire">
                  <span>
                    <Compair />
                  </span>
                </a>
                <span className={`w-[18px] h-[18px] rounded-full  absolute -top-2.5 -right-2.5 flex justify-center items-center text-[9px] ${type === 3 ? 'bg-qh3-blue text-white' : 'bg-qyellow'}`}>
                  2
                </span>
              </div>*/}
              <div className="favorite relative">
                <a href={`/${slug}/wishlist`}>
                  <span>
                    <ThinLove />
                  </span>
                </a>
                {/*<span className={`w-[18px] h-[18px] rounded-full  absolute -top-2.5 -right-2.5 flex justify-center items-center text-[9px] ${type === 3 ? 'bg-qh3-blue text-white' : 'bg-qyellow'}`}>
                  1
                  </span>*/}
              </div>

              <div className="cart-wrapper group relative py-4">
                <div className="cart relative cursor-pointer">
                  <a href={`/${slug}/cart`}>
                    <span>
                      <ThinBag />
                    </span>
                  </a>
                  <span
                    className={`w-[20px] h-[20px] rounded-full  absolute  flex justify-center items-center text-[9px] ${type === 3 ? 'bg-qh3-blue text-white' : 'bg-qyellow'}`}
                    style={{ fontSize: '12.5px', top: '-10px', right: '-12px', fontWeight: 'bold', color: 'black' }}
                  >
                    {getTotalQuantity()}
                  </span>
                </div>
                {/* <div className="fixed left-0 top-0 w-full h-full z-40"></div> */}
                {/* hidden group-hover:block" */}
                <Cart type={type} cart={cart} className="absolute -right-[45px] top-11 z-50 hidden group-hover:block" slug={slug} />
              </div>

              <div>
                <button type="button">
                  <span>
                    <ThinPeople />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}
