import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import CartProvider from './CartContext'; // นำเข้า CartProvider
//import Routers from "./Routers";
import About from "./components/About";
import AllProductPage from "./components/AllProductPage";
import Login from "./components/Auth/Login/index";
import Profile from "./components/Auth/Profile";
import Signup from "./components/Auth/Signup";
import BecomeSaller from "./components/BecomeSaller";
import Blogs from "./components/Blogs";
import Blog from "./components/Blogs/Blog.jsx";
import CardPage from "./components/CartPage";
import CheakoutPage from "./components/CheakoutPage";
import Contact from "./components/Contact";
import Faq from "./components/Faq";
import FlashSale from "./components/FlashSale";
import FourZeroFour from "./components/FourZeroFour";
import HomeThree from "./components/HomeThree";
import OrderPlaced from "./components/OrderPlaced/index.jsx";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ProductsCompaire from "./components/ProductsCompaire/index";
import SallerPage from "./components/SallerPage";
import Sallers from "./components/Sellers";
import SingleProductPage from "./components/SingleProductPage";
import TermsCondition from "./components/TermsCondition/index";
import TrackingOrder from "./components/TrackingOrder";
import Wishlist from "./components/Wishlist";

export default function App() {
  return (
    <div  style={{width:"100%",height:"100%"  }} >
      <CartProvider> {/* ห่อ components ทั้งหมดด้วย CartProvider */}
      <Router>
        <Routes>
        <Route exact path="/:slug" element={<HomeThree />} />
        <Route exact path="/:slug/all-products" element={<AllProductPage />} />
        <Route exact path="/:slug/single-product/:productId" element={<SingleProductPage />} />
        <Route exact path="/:slug/cart" element={<CardPage />} />
        <Route exact path="/:slug/checkout" element={<CheakoutPage />} />
        <Route exact path="/:slug/wishlist" element={<Wishlist />} />
        <Route exact path="/:slug/flash-sale" element={<FlashSale />} />
        <Route exact path="/:slug/saller-page" element={<SallerPage />} />
        <Route exact path="/:slug/products-compaire" element={<ProductsCompaire />} />
        <Route exact path="/:slug/sallers" element={<Sallers />} />
        <Route exact path="/:slug/about" element={<About />} />
        <Route exact path="/:slug/blogs" element={<Blogs />} />
        <Route exact path="/:slug/blogs/blog" element={<Blog />} />
        <Route exact path="/:slug/tracking-order" element={<TrackingOrder />} />
        <Route exact path="/:slug/contact" element={<Contact />} />
        <Route exact path="/:slug/faq" element={<Faq />} />
        <Route exact path="/:slug/login" element={<Login />} />
        <Route exact path="/:slug/signup" element={<Signup />} />
        <Route exact path="/:slug/profile" element={<Profile />} />
        <Route exact path="/:slug/become-saller" element={<BecomeSaller />} />
        <Route exact path="/:slug/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/:slug/terms-condition" element={<TermsCondition />} />
        <Route exact path="/:slug/order-placed/:orderId" element={<OrderPlaced />} />
        <Route exact path="*" element={<FourZeroFour />} />
        <Route path="*" element={<Navigate to="/:slug" /> } />
      </Routes>
    </Router>
    </CartProvider>
    </div>
  );
}
